import {HTableConfig} from "../../ui/components/Table";

export const tableConfig: HTableConfig = {
  heads: [
    {
      key: 'name',
      label: 'Название'
    },
  ]

}
