import { useState } from 'react'

export const useModal = () : [boolean, (obj?: Record<string, any> | null) => void, () => void, Record<string, any> | null] => {
  const [isShowing, setIsShowing] = useState(false);
  const [obj, setObj] = useState<Record<string, any> | null>(null)

  function openModal(obj: Record<string, any> | null = null) {
    setIsShowing(true);
    setObj(obj)
  }

  function closeModal() {
    setIsShowing(false)
    setObj(null)
  }

  return [
    isShowing,
    openModal,
    closeModal,
    obj
  ];
}
