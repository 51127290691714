import React from "react";
import {AiOutlineCar, AiOutlineIdcard} from "react-icons/ai";
import {TripType} from "../../../types/models";
import {FiEdit2} from "react-icons/fi";
import {BsTrash3} from "react-icons/bs";

export function TripsElement({onPress, onDelete, onEditPress, trip}: {
  onPress: () => void
  onEditPress: () => void,
  onDelete: () => void
  trip: TripType
}) {

  let color = '#34bfa3'

  if (trip.freePlaces - trip.activeTickets < 5) {
    color = '#eac459'
  }

  if (trip.freePlaces - trip.activeTickets <= 0) {
    color = '#ff0028'
  }

  if (trip.closed === 1) {
    color = '#d6d6d6'
  }

  return (
    <div className="trip" style={{backgroundColor: color}}>
      <div className="trip-icons">
        <span className="trip-icon" onClick={onEditPress}><FiEdit2/></span>
        <span className="trip-icon" onClick={onDelete}><BsTrash3/></span>
      </div>
      <span className="trip-time" onClick={onPress}>{trip.time}</span>
      <span className="trip-info" onClick={onPress}>{trip.activeTickets} из  {trip.freePlaces}</span>
      <div className="trip-dop-icons">
        {trip.car && (
          <div className="trip-dop-icon">
            <AiOutlineCar/>
          </div>
        )}
        {trip.driver && (
          <div className="trip-dop-icon">
            <AiOutlineIdcard/>
          </div>
        )}
      </div>
    </div>
  )
}
