import React, {useEffect, useState} from "react";
import {HModal} from "../../../ui";
import {useFormStore} from "../../../ui/hooks/useFormStore";
import {ticketModalFields, ticketEditModalStructure} from "../constants";
import {TicketType, TripType} from "../../../types/models";
import {apiTripLoadData} from "../../../actions/tripsActions";
import {useSelector} from "react-redux";
import {AppState} from "../../../reducers/store";
import {apiTicketUpdate} from "../../../actions/ticketsActions";

export function TicketEditModal({onClose, onUpdate, open, trip, ticket}: {
  onClose: () => void,
  onUpdate: () => void,
  open: boolean,
  ticket: TicketType
  trip: TripType
}) {

  const token = useSelector((state: AppState) => state.user.token)

  const [formStore, getForm] = useFormStore(ticketModalFields, ticketEditModalStructure)
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  useEffect(() => {
    init()
  }, [ticket])

  const init = async () => {

    setLoading(true)

    if (token) {
      const response = await apiTripLoadData(token, trip.id)

      formStore.setValue({
        key: 'places',
        value: ticket.places
      })
      formStore.setValue({
        key: 'comment',
        value: ticket.comment
      })

      formStore.setValue({
        key: 'stationFrom',
        value: ticket.stationFrom
      })
      formStore.setProps('stationFrom', {
        elements: response.stationsFrom
      })

      formStore.setValue({
        key: 'stationTo',
        value: ticket.stationTo
      })
      formStore.setProps('stationTo', {
        elements: response.stationsTo
      })

      setLoading(false)
    }

  }

  const submit = async () => {

    const isValid = formStore.validate();
    if (isValid) {
      setSubmitLoading(true)
      if (token) {
        const response = await apiTicketUpdate(token, ticket.id, formStore.getValues())
        if (response.status) {
          onUpdate()
          formStore.clean()
        } else {
          alert(response.error)
        }
        setSubmitLoading(false)
      }
    }

  }

  return (
    <HModal
      open={open}
      onClose={onClose}
      content={(
        <>
          {loading ? (<div/>) : (getForm())}
        </>
      )}
      title={'Редактировать бронь'}
      isLoading={submitLoading}
      onSubmit={submit}
    />
  )
}
