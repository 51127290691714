import {HField} from "../types";

export const generateInitialErrors = (fields: HField[]) => {

  const errors: Record<string, string | false> = {}

  fields.forEach(field => {
    errors[field.key] = false;
  })

  return errors

}
