import {API_URL} from "../constants";
import axios from "axios";

export const apiTripsGet = (token: string, route_id: number): any => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/trips?route_id=${route_id}&date=2023-10-11`, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiTripCreate = (token: string, {
  date,
  time,
  route_id,
  car,
  driver,
}: any): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/trip/create`, {
        date,
        time,
        route_id,
        car_id: car ? car.id : null,
        driver_id: driver ? driver.id : null,
      }, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};


export const apiTripUpdate = (token: string, id: number, {
  date,
  time,
  route_id,
  car,
  driver,
}: any): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/trip/update`, {
        id,
        time,
        car_id: car ? car.id : null,
        driver_id: driver ? driver.id : null,
      }, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiTripDelete = (token: string, id: number): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/trip/delete`, {
        id,
      }, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiTripLoadData = (token: string, tripId: number): any => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/trip/data?id=${tripId}`, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
