import {DataSetUpdateFunction} from "../ui/components/DataSet/DataSet";

type GenerateUpdateMethod = (token: string | null, method: Function) => DataSetUpdateFunction

export const generateUpdateMethod: GenerateUpdateMethod = (token, method) => {

  return (id, obj) => {
    return new Promise(async resolve => {
      await method(token, id, obj)
      resolve({})
    })
  }

}
