import {HField, HFormStore, HFormStructure} from "../types";
import {Label} from "../components/Label/Label";
import {TextInput} from "../components/TextInput/TextInput";
import React from "react";
import {Select} from "../components/Select/Select";
import {HDatePicker} from "../components/DatePicker/DatePicker";

export const renderFields = ({
  structure,
  fields,
  formStore
}: {
  structure: HFormStructure,
  fields: HField[],
  formStore: HFormStore
}) => {

  return (
    <>
      {
        structure.map((element: any) => {
          if (Array.isArray(element)) {
            return (
              <div className="h-label-row">
                {
                  element.map((child: string) => {
                    return getField(child, fields, formStore)
                  })
                }
              </div>
            )
          }
          return getField(element, fields, formStore)
        })
      }
    </>
  )

}

const getField = (element: string, fields: HField[], formStore: HFormStore) => {

  const field: HField | undefined = fields.find(_field => _field.key === element)

  if (field && !field.hidden) {
    return renderField(field, formStore)
  }
  return null;

}

function renderField(field: HField, formStore: HFormStore) {

  return (
    <Label key={field.key} label={field.label ? field.label: ''} required={field.required} error={formStore.getError(field.key)}>
      <>
        {typeof field.component === "string" ? (
          <>
            {field.component === 'textInput' && (
              <TextInput
                {...field}
                value={formStore.getValue(field.key)}
                onChange={(value) => {
                  formStore.setValue({
                    key: field.key,
                    value
                  })
                }}
                error={formStore.getError(field.key)}
              />
            )}
            {field.component === 'select' && (
              <Select
                {...field}
                value={formStore.getValue(field.key)}
                onChange={(value) => {
                  formStore.setValue({
                    key: field.key,
                    value
                  })
                }}
              />
            )}
            {field.component === 'datePicker' && (
              <HDatePicker
                {...field}
                value={formStore.getValue(field.key)}
                onChange={(value) => {
                  formStore.setValue({
                    key: field.key,
                    value
                  })
                }}
              />
            )}
          </>
        ) : (
          <>
            {field.component({
              ...field,
              value: formStore.getValue(field.key),
              onChange: (value: any) => {
                formStore.setValue({
                  key: field.key,
                  value
                })
              }
            })}
          </>
        )}
      </>
    </Label>
  )

}
