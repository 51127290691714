import {StationType} from "../../../types/models";
import {FiEdit2} from "react-icons/fi";
import {BsTrash3} from "react-icons/bs";
import React from "react";

const stationTypeToValue: Record<string, string> = {
  from: 'Место посадки',
  to: 'Место высадки'
}

export default function StationBlock({station, onDeletePress, onEditPress}: {
  station: StationType,
  onEditPress: () => void
  onDeletePress: () => void
}) {

  const onDelete = () => {

    if (window.confirm('Удалить останоку?')) {
      onDeletePress()
    }

  }

  return (
    <div className="station-block" key={station.id}>
      <div className="station-block-icons">
        <span className="station-block-icon" onClick={onEditPress}><FiEdit2 size={10}/></span>
        <span className="station-block-icon" onClick={onDelete}><BsTrash3 size={10}/></span>
      </div>
      <span className="station-block-name">{station.name}</span>
      <span className="station-block-city">{station.city && station.city.name}</span>
      <div className="station-block-row">
        <span className="station-block-type">{stationTypeToValue[station.type]}</span>
        <span className="station-block-time">{station.time !== 0 && `${station.time} мин`}</span>
      </div>
    </div>
  )

}
