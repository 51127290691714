import React, {useEffect, useState} from 'react';
import {Select} from "../../ui";
import {RouteType} from "../../types/models";
import {apiRoutesGet} from "../../actions/routeActions";
import {useSelector} from "react-redux";
import {AppState} from "../../reducers/store";

export default function RouteSelect({value, onChange, width}: {
  value: RouteType | null,
  onChange: (value: RouteType) => void,
  width?: string
}) {

  const token = useSelector((state: AppState) => state.user.token)
  const [routes, setRoutes] = useState([])

  useEffect(() => {
    loadElements()
  }, [])

  const loadElements = async () => {

    if (token) {
      const routes = await apiRoutesGet(token)
      setRoutes(routes)
      if (routes.length > 0) {
        onChange(routes[0])
      }
    }

  }

  return (
    <Select
      value={value}
      placeholder={'Выберите маршрут'}
      elements={routes}
      onChange={onChange}
      renderValue={(route: RouteType) => route.name}
      width={width}
    />
  )

}
