import thunk from 'redux-thunk';
import {userReducer, UserState} from './user';
import {applyMiddleware, combineReducers, createStore} from "redux";

export type AppState = {
  user: UserState
}

declare module 'react-redux' {
  interface DefaultRootState extends AppState {}
}

const rootReducer = combineReducers({
  user: userReducer,
});

export const store = createStore(rootReducer, applyMiddleware(thunk));
