import React, {useEffect, useState} from 'react';
import {Select} from "../../ui";
import {DriverType} from "../../types/models";
import {apiDriversGet} from "../../actions/driverActions";
import {useSelector} from "react-redux";
import {AppState} from "../../reducers/store";

export default function DriverSelect({value, onChange}: {
  value: any,
  onChange: (value: any) => void,
}) {

  const token = useSelector((state: AppState) => state.user.token)
  const [drivers, setDrivers] = useState([])

  useEffect(() => {
    loadElements()
  }, [])

  const loadElements = async () => {

    if (token) {
      const response = await apiDriversGet(token, {offset: 0, limit: 50, search: ''})
      setDrivers(response.elements)
    }

  }

  return (
    <Select
      value={value}
      placeholder={'Водитель'}
      elements={drivers}
      onChange={onChange}
      renderValue={(driver: DriverType) => driver.fio}
      nullValue={'Не назначен'}
    />
  )

}
