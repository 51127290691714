import {Button} from "../Button/Button";
import React, {useEffect, useState} from "react";
import {HTableConfig, Table} from "../Table";
import {HField, HFormStructure} from "../../types";
import {HModal, HModalProps} from "../Modal/Modal";
import {useFormStore} from "../../hooks/useFormStore";
import {isEmpty} from "../../utils/isEmpty";
import {useModal} from "../../hooks/useModal";

export type DataSetGetFunction = (params: {offset: number, limit: number, search: string}) => Promise<{
  num: number,
  elements: any
}>

export type DataSetCreateFunction = (newObject: Record<string, any>) => Promise<any>
export type DataSetUpdateFunction = (id: number, fields: Record<string, any>) => Promise<any>
export type DataSetDeleteFunction = (id: number) => Promise<any>

export type DataSetConfig = {
  title: string,
  tableConfig: HTableConfig,
  methods?: {
    get?: DataSetGetFunction,
    create?: DataSetCreateFunction
    update?: DataSetUpdateFunction,
    delete?: DataSetDeleteFunction
  }
  fields?: HField[],
  modals?: {
    create?: {
      title: string,
      structure: HFormStructure,
      plugins?: Function[]
    }
    update?: {
      title: string,
      structure: HFormStructure,
      plugins?: Function[],
      generateFields?: (element: Record<string, any>) => Record<string, any>
    }
  }
}

export function DataSetModal({open, onClose, onUpdate, obj, fields, structure, type, title, method, generateFields, plugins = [], headers}: HModalProps & {
  obj?: Record<string, any>,
  fields: HField[],
  structure: HFormStructure,
  type: 'create' | 'update'
  title: string
  method: DataSetCreateFunction | DataSetUpdateFunction,
  generateFields?: (element: Record<string, any>) => Record<string, any>,
  plugins?: Function[]
}) {

  const [formStore, getForm] = useFormStore(fields, structure)
  const [loading, setLoading] = useState(false)

  plugins?.forEach(plugin => {
    plugin(formStore)
  })

  useEffect(() => {

    if (!isEmpty(obj) && obj) {
      const _obj = generateFields ? generateFields(obj) : obj
      Object.keys(_obj).forEach(key => {
        formStore.setValue({
          key,
          value: _obj[key]
        })
      })
    }

  }, [obj])

  const submit = async () => {

    const isValid = formStore.validate();
    console.log(formStore.getErrors())
    if (isValid) {
      setLoading(true)
      if (type === 'create') {
        // @ts-ignore
        await method(formStore.getValues())
      }
      if (type === 'update') {
        // @ts-ignore
        await method(obj['id'], formStore.getValues())
      }
      formStore.clean()
      setLoading(false)
      onUpdate && onUpdate()
    }

  }

  const closeModal = () => {
    formStore.clean()
    onClose()
  }

  return (
    <HModal
      open={open}
      onClose={closeModal}
      content={getForm()}
      headers={headers}
      title={title}
      onSubmit={submit}
      isLoading={loading}
    />
  )

}

export function DataSet({
  title,
  tableConfig,
  methods,
  modals,
  fields
}: DataSetConfig) {

  const [elements, setElements] = useState([])
  const [num, setNum] = useState(0)

  const [createModalOpen, openCreateModal, closeCreateModal] = useModal()
  const [updateModalOpen, openUpdateModal, closeUpdateModal, updateModalObj] = useModal()

  useEffect(() => {
    methods?.get && loadElements()
  }, [])

  const loadElements = async () => {

    if (methods?.get) {

      const response = await methods.get({
        offset: 0,
        limit: 50,
        search: ''
      });
      setElements(response.elements)
      setNum(response.num)

    }

  }

  const openDeleteModal = async (element: Record<string, any>) => {

    if (methods?.delete && window.confirm('Удалить элемент?')) {
      await methods.delete(element.id)
      loadElements()
    }

  }

  return (
    <div className="page">
      {fields && modals?.create && methods?.create &&
        <DataSetModal
          open={createModalOpen}
          onClose={closeCreateModal}
          fields={fields}
          structure={modals.create.structure}
          plugins={modals.create.plugins}
          title={modals.create.title}
          type={'create'}
          method={methods.create}
          onUpdate={() => {
            loadElements()
            closeCreateModal()
          }}
        />
      }
      {fields && modals?.update && methods?.update && updateModalObj &&
        <DataSetModal
          open={updateModalOpen}
          onClose={closeUpdateModal}
          fields={fields}
          structure={modals.update.structure}
          title={modals.update.title}
          plugins={modals.update.plugins}
          type={'update'}
          method={methods.update}
          onUpdate={() => {
            loadElements()
            closeUpdateModal()
          }}
          obj={updateModalObj}
          generateFields={modals.update.generateFields}
        />
      }
      <div className="page-top">
        <div className="page-top-left">
          <span className="page-title">{title}</span>
        </div>
        <div className="page-top-right">
          {methods?.create && <Button onClick={openCreateModal} label={'Создать'} type={'primary'} size={'small'}/>}
        </div>
      </div>
      <div className="page-content">
        <Table
          tableConfig={tableConfig}
          elements={elements}
          num={num}
          onEditPress={element => {
            openUpdateModal(element)
          }}
          onDeletePress={element => {
            openDeleteModal(element)
          }}
        />
      </div>
    </div>
  )

}
