export class EventEmitter {

  private events: any;

  constructor() {
    this.events = {};
  }

  subscribe(eventName: string, callback: Function) {
    !this.events[eventName] && (this.events[eventName] = []);
    this.events[eventName].push(callback);
  }

  unsubscribe(eventName: string, callback: Function) {
    this.events[eventName] = this.events[eventName].filter((eventCallback: any) => callback !== eventCallback);
  }

  emit(eventName: string, args: any) {
    const event = this.events[eventName];
    event && event.forEach((callback: any) => callback.call(null, args));
  }
}
