import React from 'react';
import {
  DataSet,
} from "../../ui/components/DataSet/DataSet";
import {fields} from "./fields";
import {modalStructure} from "./structure";
import {tableConfig} from "./table";
import {generateCreateMethod} from "../../utils/generateCreateMethod";
import {generateUpdateMethod} from "../../utils/generateUpdateMethod";
import {generateDeleteMethod} from "../../utils/generateDeleteMethod";
import {generateGetMethod} from "../../utils/generateGetMethod";
import {apiPriceCreate, apiPriceDelete, apiPricesGet, apiPriceUpdate} from "../../actions/priceActions";
import {useSelector} from "react-redux";
import {AppState} from "../../reducers/store";

function Prices() {

  const token = useSelector((state: AppState) => state.user.token)

  return (
   <>
     <DataSet
       title={'Цены'}
       tableConfig={tableConfig}
       methods={{
         get: generateGetMethod(token, apiPricesGet),
         create: generateCreateMethod(token, apiPriceCreate),
         update: generateUpdateMethod(token, apiPriceUpdate),
         delete: generateDeleteMethod(token, apiPriceDelete)
       }}
       modals={{
         create: {
           title: 'Настройка цены',
           structure: modalStructure,
         },
         update: {
           title: 'Настройка цены',
           structure: modalStructure,

         }
       }}
       fields={fields}
     />
   </>
  );
}

export default Prices;
