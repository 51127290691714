import React, {useEffect} from 'react';
import {Header, LeftMenu} from "../components";
import {Outlet, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../reducers/store";

function AuthLayout() {

  const isLoggedIn = useSelector((state: AppState) => state.user.isLoggedIn)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login')
    }
  }, [isLoggedIn]);

  return (
    <>
      <Header/>
      <LeftMenu/>
      <div className="content">
        <Outlet/>
      </div>
    </>
  )

}

export default AuthLayout
