import React from "react";
import {FiPlus} from "react-icons/fi";
import moment from "moment";
import {getDateTimeString} from "../../../utils/getDateTimeString";

export function TripsTopElement({onPress, date, num}: {
  onPress: () => void
  date: string
  num: number
}) {
  return (
    <div className="trips-top-element">
      <div className="trips-top-element-icon" onClick={onPress}>
        <FiPlus/>
      </div>
      <span className="trips-top-element-date">{getDateTimeString(moment(date).toDate())}</span>
      <span className="trips-top-element-trips-num">рейсов: {num}</span>
    </div>
  )
}
