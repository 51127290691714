import React from 'react';
import {
  DataSet,
} from "../../ui/components/DataSet/DataSet";
import {fields} from "./fields";
import {modalStructure} from "./structure";
import {tableConfig} from "./table";
import {generateCreateMethod} from "../../utils/generateCreateMethod";
import {generateUpdateMethod} from "../../utils/generateUpdateMethod";
import {generateDeleteMethod} from "../../utils/generateDeleteMethod";
import {generateGetMethod} from "../../utils/generateGetMethod";
import {apiCitiesGet, apiCityCreate, apiCityDelete, apiCityUpdate} from "../../actions/cityActions";
import {useSelector} from "react-redux";
import {AppState} from "../../reducers/store";

function Cities() {

  const token = useSelector((state: AppState) => state.user.token)

  return (
   <>
     <DataSet
       title={'Населенные пункты'}
       tableConfig={tableConfig}
       methods={{
         get: generateGetMethod(token, apiCitiesGet),
         create: generateCreateMethod(token, apiCityCreate),
         update: generateUpdateMethod(token, apiCityUpdate),
         delete: generateDeleteMethod(token, apiCityDelete)
       }}
       modals={{
         create: {
           title: 'Населенный пункт',
           structure: modalStructure,
         },
         update: {
           title: 'Населенный пункт',
           structure: modalStructure
         }
       }}
       fields={fields}
     />
   </>
  );
}

export default Cities;
