import {HFormStore} from "../../../ui/types";

export const passwordFieldPlugin = (formStore: HFormStore) => {

  formStore.emitter.subscribe('change.hasMobileAccess', (value: string) => {
    formStore.setProps('password', {
      hidden: value === 'Нет'
    })
    formStore.setProps('newPassword', {
      hidden: value === 'Нет'
    })

    if (value === 'Нет') {
      formStore.setValue({
        key: 'password',
        value: ''
      })
      formStore.setValue({
        key: 'newPassword',
        value: ''
      })
    }
  })

}
