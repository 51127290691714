import React from 'react';
import {
  DataSet,
} from "../../ui/components/DataSet/DataSet";
import {apiCarCreate, apiCarUpdate, apiCarDelete, apiCarsGet} from "../../actions/carActions";
import {fields} from "./fields";
import {modalStructure} from "./structure";
import {tableConfig} from "./table";
import {generateCreateMethod} from "../../utils/generateCreateMethod";
import {generateUpdateMethod} from "../../utils/generateUpdateMethod";
import {generateDeleteMethod} from "../../utils/generateDeleteMethod";
import {generateGetMethod} from "../../utils/generateGetMethod";
import {useSelector} from "react-redux";
import {AppState} from "../../reducers/store";

function Cars() {

  const token = useSelector((state: AppState) => state.user.token)

  return (
   <>
     <DataSet
       title={'Автомобили'}
       tableConfig={tableConfig}
       methods={{
         get: generateGetMethod(token, apiCarsGet),
         create: generateCreateMethod(token, apiCarCreate),
         update: generateUpdateMethod(token, apiCarUpdate),
         delete: generateDeleteMethod(token, apiCarDelete)
       }}
       modals={{
         create: {
           title: 'Автомобиль',
           structure: modalStructure,
         },
         update: {
           title: 'Автомобиль',
           structure: modalStructure
         }
       }}
       fields={fields}
     />
   </>
  );
}

export default Cars;
