import {DataSetDeleteFunction} from "../ui/components/DataSet/DataSet";

type GenerateDeleteMethod = (token: string | null, method: Function) => DataSetDeleteFunction

export const generateDeleteMethod: GenerateDeleteMethod = (token, method) => {

  return id => {
    return new Promise(async resolve => {
      await method(token, id)
      resolve({})
    })
  }

}
