import {API_URL} from "../constants";
import axios from "axios";
import Cookies from "js-cookie";

export const apiUserAuth = ({
  email,
  password
}: {
  email: string,
  password: string
}): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/user/auth`, {
        email,
        password
      }, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const userSession = () => {

  return new Promise<null | {user: object, token: string}>(async (resolve, reject) => {

    const token = Cookies.get('token');

    if (token !== undefined) {

      try {

        const apiResponse = await apiUserSession(token);
        resolve({
          token: apiResponse.token,
          user: apiResponse.user
        });

      } catch (error) {
        resolve(null);
      }

    }

    resolve(null);

  })

}

export const apiUserSession = (token: string): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + '/user/session', {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}
