import {HField, HFormErrors, HFormStore, HFormStructure, HFormValues} from "../types";
import {useState} from "react";
import {generateInitialState} from "../utils/generateInitialState";
import {generateInitialErrors} from "../utils/generateInitialErrors";
import {renderFields} from "../utils/renderFields";
import {isEmpty} from "../utils/isEmpty";
import {DEFAULT_ERROR_TEXT} from "../constants/text";
import {EventEmitter} from "../utils/EventEmitter";

const generateFields = (fields: HField[], structure: HFormStructure) => {

  const result: HField[] = []
  const _structure = structure.reduce((acc, value) => {
    if (typeof value === 'string') {
      // @ts-ignore
      return [...acc, value]
    }
    // @ts-ignore
    return [...acc, ...value]

  }, [])

  fields.forEach(field => {
    if (_structure.indexOf(field.key) !== -1) {
      result.push(field)
    }
  })

  return result

}

export const useFormStore = (_fields: HField[], structure: HFormStructure) : [HFormStore, any] => {

  const [fields, setFields] = useState(generateFields(_fields, structure))
  const [values, setValues] = useState(generateInitialState(fields))
  const [errors, setErrors] = useState(generateInitialErrors(fields))

  const emitter = new EventEmitter();

  function setValue({key, value}: {
    key: string,
    value: any
  }) {
    setValues((values) => {
      return {
        ...values,
        [key]: value
      }
    })
    setErrors((errors) => {
      return {
        ...errors,
        [key]: false
      }
    })
    emitter.emit(`change.${key}`, value)
  }

  function setError({key, error}: {
    key: string,
    error: string | false
  }) {
    setErrors((errors) => {
      return {
        ...errors,
        [key]: error
      }
    })
  }

  function getValues(): HFormValues {
    return values
  }

  function getValue(key: string) {
    return values[key]
  }

  function getErrors(): HFormErrors {
    return errors
  }

  function getError(key: string) {
    return errors[key]
  }

  function validate() {

    let isValid = true
    let errors: HFormErrors = {}

    fields.forEach(field => {
      let error: string | false = false
      if (field.required && isEmpty(values[field.key])) {
        error = DEFAULT_ERROR_TEXT
        isValid = false
      }
      errors[field.key] = error
    })

    setErrors(errors)

    return isValid

  }

  function clean() {
    setValues({
      ...generateInitialState(fields)
    })
    setErrors({
      ...generateInitialErrors(fields)
    })
  }

  function setProps(key: string, props: Record<string, any>) {

    setFields((fields) => {
      return fields.map(field => {
        if (field.key === key) {
          return {
            ...field,
            ...props
          }
        }
        return field
      })
    })

  }

  const formStore = {
    getValues,
    getValue,
    getErrors,
    getError,
    setValue,
    setProps,
    setError,
    validate,
    clean,
    emitter
  }

  return [
    formStore,
    () => {
      return renderFields({
        structure,
        fields,
        formStore
      })
    }
  ]

}
