export const stationTypes = [
  {
    id: 'from',
    name: 'Место посадки'
  },
  {
    id: 'to',
    name: 'Место высадки'
  }
]
