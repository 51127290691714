import {HTableConfig} from "../../ui/components/Table";

export const tableConfig: HTableConfig = {
  heads: [
    {
      key: 'mark',
      label: 'Марка'
    },
    {
      key: 'model',
      label: 'Модель'
    },
    {
      key: 'color',
      label: 'Цвет'
    },
    {
      key: 'number',
      label: 'Госномер'
    },
    {
      key: 'capacity',
      label: 'Вместимость'
    }
  ]

}
