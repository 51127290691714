import React, {useEffect, useState} from "react";
import {ClientType, TicketType, TripType} from "../../../types/models";
import {Button, HModal, useModal} from "../../../ui";
import moment from "moment";
import {apiTicketChangeStatus, apiTicketsGet, apiTicketsGetClients} from "../../../actions/ticketsActions";
import HTabs from "../../../ui/components/Tabs/Tabs";
import {Table} from "../../../ui/components/Table";
import {ticketsClientsTableConfig, ticketsTableConfig} from "../constants";
import {useSelector} from "react-redux";
import {AppState} from "../../../reducers/store";
import {getDateTimeString} from "../../../utils/getDateTimeString";
import {TicketEditModal} from "./TicketEditModal";
import {TicketCreateModal} from "./TicketCreateModal";

export function ClientsModal({onClose, client, open}: {
  onClose: () => void,
  client: ClientType,
  open: boolean
}) {
  return (
    <HModal
      open={open}
      onClose={onClose}
      content={<ClientsModalContent client={client}/>}
      title={'Карточка клиента'}
      disableFooter={true}
    />
  )
}

function ClientsModalContent({client}: {
  client: ClientType | null
}) {

  const token = useSelector((state: AppState) => state.user.token)

  const [tickets, setTickets] = useState<TicketType[]>([])
  const [tab, setTab] = useState('Активные брони')

  const [editTicketModalOpen, openEditTicketModal, closeEditTicketModal, editTicketModalObj] = useModal()

  useEffect(() => {
    loadTickets()
  }, [client])

  const loadTickets = async () => {

    if (client && token) {
      const response = await apiTicketsGetClients(token, client.id);
      setTickets(response.filter((element: any) => element.trip !== null).map((element: any) => {
        return {
          id: element.id,
          date: element.trip.date,
          time: element.trip.time,
          stationFrom: element.station_from,
          stationTo: element.station_to,
          places: element.places,
          comment: element.comment,
          status: element.status,
          closed: element.trip.closed,
          trip: element.trip
        }
      }))
    }

  }

  const checkTicketStatus = (ticket: TicketType) => {

    if (tab === 'Завершенные') {
      // @ts-ignore
      return ticket.closed === 1
    }

    if (tab === 'Активные брони') {
      // @ts-ignore
      return [1,2,3,4].indexOf(ticket.status) !== -1 && ticket.closed === 0
    }

    if (tab === 'Отмененные') {
      // @ts-ignore
      return [0,6].indexOf(ticket.status) !== -1 && ticket.closed === 0
    }

    if (tab === 'Резерв') {
      // @ts-ignore
      return [5].indexOf(ticket.status) !== -1 && ticket.closed === 0
    }

  }

  return (
    <div className="tickets-modal">
      {editTicketModalObj && (
        <TicketEditModal
          open={editTicketModalOpen}
          onClose={() => {
            closeEditTicketModal()
          }}
          onUpdate={() => {
            loadTickets()
            closeEditTicketModal()
          }}
          trip={editTicketModalObj.trip}
          ticket={editTicketModalObj as TicketType}
        />
      )}
      <div className="tickets-modal-top">
        <HTabs
          tabs={['Активные брони', 'Отмененные', 'Резерв', 'Завершенные']}
          onChange={(_tab) => {
            setTab(_tab)
          }}
          activeTab={tab}
        />
        {client && (
          <div className="tickets-modal-top-right">
            <span className="tickets-modal-top-info">Клиент: <b>{client.last_name} {client.first_name}</b></span>
          </div>
        )}
      </div>
      <div className="tickets-modal-content">
        {tickets.length > 0 && (
          <Table
            tableConfig={ticketsClientsTableConfig}
            elements={tickets.filter(ticket => checkTicketStatus(ticket))}
            num={0}
            onEditPress={(ticket) => {
              openEditTicketModal(ticket)
            }}
            onElementChange={(id, key, value) => {
              if (token) {
                apiTicketChangeStatus(token, id, parseInt(value))
                setTickets([...tickets.map((ticket, index) => {
                  if (ticket.id === id) {
                    return {
                      ...ticket,
                      [key]: value
                    }
                  }
                  return ticket
                })])
              }
            }}
            type={'small'}
          />
        )}
      </div>
    </div>
  )

}
