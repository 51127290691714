import {HField} from "../../ui/types";

export const fields: HField[] = [
  {
    component: 'textInput',
    label: 'Вместимость',
    key: 'capacity',
    required: true,
    defaultValue: 15
  },
  {
    component: 'textInput',
    label: 'Марка',
    key: 'mark',
    required: true,
  },
  {
    component: 'textInput',
    label: 'Модель',
    key: 'model',
    required: true,
  },
  {
    component: 'textInput',
    label: 'Цвет',
    key: 'color',
    required: true,
  },
  {
    component: 'textInput',
    label: 'Госномер',
    key: 'number',
    required: true,
  },
]
