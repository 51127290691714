import React, {useEffect, useState} from 'react';
import {Select} from "../../ui";
import {CityType} from "../../types/models";
import {apiCitiesGet} from "../../actions/cityActions";
import {useSelector} from "react-redux";
import {AppState} from "../../reducers/store";

export default function CitySelect({value, onChange}: {
  value: any,
  onChange: (value: any) => void,
}) {

  const token = useSelector((state: AppState) => state.user.token)
  const [cities, setCities] = useState([])

  useEffect(() => {
    loadElements()
  }, [])

  const loadElements = async () => {

    if (token) {
      const response = await apiCitiesGet(token, {offset: 0, limit: 50, search: ''})
      setCities(response.elements)
    }

  }

  return (
    <Select
      value={value}
      placeholder={'Выберите населенный пункт'}
      elements={[
        {
          id: null,
          name: 'Город не выбран'
        },
        ...cities
      ]}
      onChange={onChange}
      renderValue={(city: CityType) => city.name}
    />
  )

}
