import {DataSetGetFunction} from "../ui/components/DataSet/DataSet";

type GenerateGetMethod = (token: string | null, method: Function) => DataSetGetFunction

export const generateGetMethod: GenerateGetMethod = (token, method) => {

  return ({offset, limit, search}) => {
    return new Promise(async resolve => {
      const response = await method(token, {
        offset,
        limit,
        search
      })
      resolve({
        num: response.num,
        elements: response.elements
      })
    })
  }

}
