import {API_URL} from "../constants";
import axios from "axios";

export const apiStationCreate = (token: string, {
  name,
  city,
  type,
  route_id,
  time
}: any): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/station/create`, {
        name,
        city_id: city ? city.id : null,
        type: type ? type.id : null,
        route_id,
        time,
      }, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiStationUpdate = (token: string, id: number, {
  name,
  city,
  type,
  time
}: any): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/station/update`, {
        id,
        name,
        city_id: city ? city.id : null,
        type: type ? type.id : null,
        time,
      }, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiStationDelete = (token: string, id: number): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/station/delete`, {
        id,
      }, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiStationsGet = (token: string, route_id: number): any => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/stations?route_id=${route_id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data.stations.map((station: Record<string, any>) => {
          return {
            ...station,
            initPrice: station.init_price,
            additionalPrice: station.additional_price

          }
        }));
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

