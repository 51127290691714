import {HTableConfig} from "../../ui/components/Table";
import {HField, HFormStructure} from "../../ui/types";
import {StationType} from "../../types/models";
import DriverSelect from "../../components/fields/DriverSelect";
import CarSelect from "../../components/fields/CarSelect";
import {StatusSelect} from "./components/StatusSelect";

export const ticketsPlacesLabelMap: string[] = ['', 'место' , 'места', 'места' , 'места', 'мест', 'мест', 'мест', 'мест', 'мест', 'мест', 'мест', 'мест', 'мест', 'мест', 'мест']

export const ticketsTableConfig: HTableConfig = {
  heads: [
    {
      key: 'phone',
      label: 'Номер',
    },
    {
      key: 'fio',
      label: 'ФИО',
    },
    {
      key: 'places',
      label: 'Места',
    },
    {
      key: 'stationFrom',
      label: 'Место посадки',
      render: (value: any) => value ? value.name : 'Не назначена'
    },
    {
      key: 'stationTo',
      label: 'Место высадки',
      render: (value: any) => value ? value.name : 'Не назначена'
    },
    {
      key: 'status',
      label: 'Статус',
      component: StatusSelect,
      width: 175
    }
  ]

}

export const ticketsClientsTableConfig: HTableConfig = {
  heads: [
    {
      key: 'date',
      label: 'Дата',
    },
    {
      key: 'time',
      label: 'Время',
    },
    {
      key: 'places',
      label: 'Места',
    },
    {
      key: 'stationFrom',
      label: 'Место посадки',
      render: (value: any) => value ? value.name : 'Не назначена'
    },
    {
      key: 'stationTo',
      label: 'Место высадки',
      render: (value: any) => value ? value.name : 'Не назначена'
    },
    {
      key: 'status',
      label: 'Статус',
      component: StatusSelect,
      width: 175
    }
  ]

}

export const ticketModalFields: HField[] = [
  {
    key: 'phone',
    component: 'textInput',
    label: 'Номер телефона',
    required: true
  },
  {
    key: 'firstName',
    component: 'textInput',
    label: 'Имя',
    required: true
  },
  {
    key: 'lastName',
    component: 'textInput',
    label: 'Фамилия',
    required: true
  },
  {
    key: 'places',
    component: 'select',
    label: 'Количество мест',
    elements: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15],
    renderValue: (value: number) => {return `${value} ${ticketsPlacesLabelMap[value]}`},
    required: true
  },
  {
    key: 'comment',
    component: 'textInput',
    label: 'Комментарий к заказу',
    placeholder: 'Например, большая сумка',
  },
  {
    key: 'stationFrom',
    component: 'select',
    required: true,
    label: 'Место посадки',
    elements: [],
    renderValue: (station: StationType) => station.name,
  },
  {
    key: 'stationTo',
    component: 'select',
    required: true,
    label: 'Место высадки',
    elements: [],
    renderValue: (station: StationType) => station.name
  }
]

export const ticketEditModalStructure: HFormStructure = [
  'places',
  'stationFrom',
  'stationTo',
  'comment'
]

export const tripModalFields: HField[] = [
  {
    component: 'textInput',
    label: 'Время',
    key: 'time',
    required: true,
    mask: '99:99'
  },
  {
    component: DriverSelect,
    label: 'Водитель',
    key: 'driver',
    required: false,
  },
  {
    component: CarSelect,
    label: 'Транспорт',
    key: 'car',
    required: false,
  },
]

export const tripModalStructure: HFormStructure = [
  'time', 'driver', 'car'
]
