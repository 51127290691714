import React, {useEffect, useState} from "react";
import {TicketType, TripType} from "../../../types/models";
import {Button, HModal, useModal} from "../../../ui";
import moment from "moment";
import {apiTicketChangeStatus, apiTicketsGet} from "../../../actions/ticketsActions";
import HTabs from "../../../ui/components/Tabs/Tabs";
import {Table} from "../../../ui/components/Table";
import {ticketsTableConfig} from "../constants";
import {useSelector} from "react-redux";
import {AppState} from "../../../reducers/store";
import {getDateTimeString} from "../../../utils/getDateTimeString";
import {TicketEditModal} from "./TicketEditModal";
import {TicketCreateModal} from "./TicketCreateModal";

export function TicketsModal({onClose, trip, open}: {
  onClose: () => void,
  trip: TripType,
  open: boolean
}) {
  return (
    <HModal
      open={open}
      onClose={onClose}
      content={<TicketsModalContent trip={trip}/>}
      title={trip ? `Список броней ${getDateTimeString(moment(trip.date).toDate())} ${trip.time}`: ''}
      disableFooter={true}
    />
  )
}

function TicketsModalContent({trip}: {
  trip: TripType | null
}) {

  const token = useSelector((state: AppState) => state.user.token)

  const [tickets, setTickets] = useState<TicketType[]>([])
  const [tab, setTab] = useState('Активные брони')

  const [editTicketModalOpen, openEditTicketModal, closeEditTicketModal, editTicketModalObj] = useModal()
  const [createTicketModalOpen, openCreateTicketModal, closeCreateTicketModal] = useModal()


  useEffect(() => {
    loadTickets()
  }, [trip])

  const loadTickets = async () => {

    if (trip && token) {
      const response = await apiTicketsGet(token, trip.id);
      setTickets(response.map((element: any) => {
        return {
          id: element.id,
          fio: `${element.client.first_name} ${element.client.last_name}`,
          phone: `${element.client.phone} (${element.client.tripsNum}/${element.client.bookingNum})`,
          stationFrom: element.station_from,
          stationTo: element.station_to,
          places: element.places,
          comment: element.comment,
          status: element.status,
        }
      }))
    }

  }

  const checkTicketStatus = (status: number) => {

    if (tab === 'Активные брони') {
      return [1,2,3,4].indexOf(status) !== -1
    }

    if (tab === 'Отмененные') {
      return [0,6].indexOf(status) !== -1
    }

    if (tab === 'Резерв') {
      return [5].indexOf(status) !== -1
    }

  }

  return (
    <div className="tickets-modal">
      {trip && editTicketModalObj && (
        <TicketEditModal
          open={editTicketModalOpen}
          onClose={() => {
            closeEditTicketModal()
          }}
          onUpdate={() => {
            loadTickets()
            closeEditTicketModal()
          }}
          trip={trip}
          ticket={editTicketModalObj as TicketType}
        />
      )}
      {trip && (
        <TicketCreateModal
          open={createTicketModalOpen}
          onClose={() => {
            closeCreateTicketModal()
          }}
          onUpdate={() => {
            loadTickets()
            closeCreateTicketModal()
          }}
          trip={trip}
        />
      )}
      <div className="tickets-modal-top">
        <HTabs
          tabs={['Активные брони', 'Отмененные', 'Резерв']}
          onChange={(_tab) => {
            setTab(_tab)
          }}
          activeTab={tab}
        />
        {trip && (
          <div className="tickets-modal-top-right">
            <span className="tickets-modal-top-info">Автомобиль: <b>{trip.car ? `${trip.car.mark} ${trip.car.model} ${trip.car.number}` : 'Не назначена'}</b></span>
            <span className="tickets-modal-top-info">Водитель: <b>{trip.driver ? `${trip.driver.fio} ${trip.driver.phone}`: 'Не назначен'}</b></span>
            {trip.closed === 0 && (
              <Button
                label={'Добавить бронь'}
                size={'small'}
                className={'tickets-modal-create-button'}
                onClick={() => {openCreateTicketModal()}}
              />
            )}
          </div>
        )}
      </div>
      <div className="tickets-modal-content">
        {tickets.length > 0 && (
          <Table
            tableConfig={ticketsTableConfig}
            elements={tickets.filter(ticket => checkTicketStatus(ticket.status))}
            num={0}
            onEditPress={(ticket) => {
              openEditTicketModal(ticket)
            }}
            onElementChange={(id, key, value) => {
              if (token) {
                apiTicketChangeStatus(token, id, parseInt(value))
                setTickets([...tickets.map((ticket, index) => {
                  if (ticket.id === id) {
                    return {
                      ...ticket,
                      [key]: value
                    }
                  }
                  return ticket
                })])
              }
            }}
            type={'small'}
          />
        )}
      </div>
    </div>
  )

}
