import React, {useEffect, useState} from 'react';
import RouteSelect from "../../components/fields/RouteSelect";
import {RouteType, TripType} from "../../types/models";
import moment from "moment";
import {TripsTopElement} from "./components/TripsTopElement";
import './Trips.css';
import {TripsElement} from "./components/TripsElement";
import {apiTripCreate, apiTripDelete, apiTripsGet, apiTripUpdate} from "../../actions/tripsActions";
import {useModal} from "../../ui";
import {TicketsModal} from "./components/TicketsModal";
import {useSelector} from "react-redux";
import {AppState} from "../../reducers/store";
import {generateUpdateMethod} from "../../utils/generateUpdateMethod";
import {generateCreateMethod} from "../../utils/generateCreateMethod";
import {DataSetModal} from "../../ui/components/DataSet/DataSet";
import {getDateTimeString} from "../../utils/getDateTimeString";
import {tripModalFields, tripModalStructure} from "./constants";
import {HDatePicker} from "../../ui/components/DatePicker/DatePicker";

type ElementWithTrips = {
 date: string,
 trips: TripType[]
}

const generateDates = (date: Date) => {

  const result = []
  let startDate = moment(date);
  for (let i = 0; i < 10; ++i) {
    result.push(startDate.format('YYYY-MM-DD'))
    startDate = startDate.add(1, 'days');
  }

  return result

}

const generateElements = (trips: TripType[], _dates: Date): ElementWithTrips[] => {

  const dates = generateDates(_dates)
  const result: ElementWithTrips[] = [];

  dates.forEach(date => {
    result.push({
      date,
      trips: trips.filter(trip => trip.date === date)
    })
  })

  return result


}

function Trips() {

  const token = useSelector((state: AppState) => state.user.token)

  const [date, setDate] = useState<Date>(moment().toDate())

  const [ticketsModalOpen, openTicketsModal, closeTicketsModal, ticketsModalObj] = useModal()
  const [tripModalOpen, openTripModal, closeTripModal, tripModalObj] = useModal()

  const [route, setRoute] = useState<RouteType | null>(null)
  const [elements, setElements] = useState<ElementWithTrips[]>([])

  useEffect(() => {
    loadTrips()
  }, [route, date])

  const loadTrips = async () => {

    if (route && token) {

      setElements([])
      const response = await apiTripsGet(token, route.id)
      setElements(generateElements(response.trips, date))

    }

  }

  const openDeleteModal = async (trip: TripType) => {

    if (token) {
      if (window.confirm('Удалить рейс?')) {
        await apiTripDelete(token, trip.id)
        loadTrips()
      }
    }

  }

  return (
    <>
      <DataSetModal
        title={'Поездка'}
        open={tripModalOpen}
        onClose={() => {
          loadTrips()
          closeTripModal()
        }}
        onUpdate={() => {
          loadTrips()
          closeTripModal()
        }}
        fields={tripModalFields}
        structure={tripModalStructure}
        type={tripModalObj?.type === 'update' ? 'update' : 'create'}
        obj={tripModalObj?.type === 'update' ? {
          route_id: route?.id,
          date: tripModalObj?.date,
          ...tripModalObj?.trip,
        } : {
          route_id: route?.id,
          date: tripModalObj?.date
        }}
        headers={[
          {
            label: 'Маршрут',
            value:  route ? route.name : ''
          },
          {
            label: 'Дата',
            value: tripModalObj ? getDateTimeString(moment(tripModalObj.date).toDate()) : ''
          }
        ]}
        method={
          tripModalObj?.type === 'update'
            ? generateUpdateMethod(token, apiTripUpdate)
            : generateCreateMethod(token, apiTripCreate)
        }
      />
      <TicketsModal
        open={ticketsModalOpen}
        onClose={() => {
          loadTrips()
          closeTicketsModal()
        }}
        trip={ticketsModalObj ? ticketsModalObj.trip : null}
      />
      <div className="page-top">
        <div className="page-top-left">
          <span className="page-title">Поездки</span>
        </div>
        <div className="page-top-right">
          <HDatePicker
            value={date}
            onChange={(value) => {
             setDate(value ? value : moment().toDate())
            }}
            className="margin-right-20"
          />
          <RouteSelect
            width={'300px'}
            value={route}
            onChange={(route) => {setRoute(route)}}
          />
        </div>
      </div>
      <div className="page-content">
        {elements.length > 0 && (
          <>
            <div className="trips-top">
              {generateDates(date).map((_date, index) => {
                return (
                  <TripsTopElement
                    onPress={() => {
                      openTripModal({
                        type: 'create',
                        date: _date
                      })
                    }}
                    key={`tripsTop${index}`}
                    date={_date}
                    num={elements.length > index ? elements[index].trips.length: 0}
                  />
                )
              })}
            </div>
            <div className="trips-content">
              {elements.map(element => {
                return (
                  <div className="trips-content-col">
                    {element.trips.map(trip => {
                      return (
                        <TripsElement
                          key={trip.id}
                          onPress={() => {
                            openTicketsModal({
                              trip
                            })
                          }}
                          onEditPress={() => {
                            openTripModal({
                              type: 'update',
                              trip
                            })
                          }}
                          onDelete={() => {
                            openDeleteModal(trip)
                          }}
                          trip={trip}
                        />
                      )
                    })}
                  </div>
                )
              })}
            </div>
          </>
        )}
      </div>
    </>
  )

}

export default Trips;
