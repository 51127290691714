import './Header.scss'
import {LogoImage} from "../../assets";
import {AiOutlineSearch, AiOutlineBell} from 'react-icons/ai';
import {NavLink} from "react-router-dom";
import {weekDaysShort} from "../../constants";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../reducers/store";
import {BiExit} from "react-icons/bi";
import {useRef, useState} from "react";
import {useOnClickOutside} from "usehooks-ts";
import {userSignOutAction} from "../../reducers/user";
import {ClientType} from "../../types/models";
import {apiClientsSearch} from "../../actions/clientActions";
import {ClientsModal} from "../../pages/Trips/components/ClientsModal";
import {useModal} from "../../ui";

function BellIcon() {

  return (
    <div className="header-icon">
      {
        /*<div className="header-icon-num">1</div>*/
      }
      <AiOutlineBell size={23}/>
    </div>
  )

}

function DateTimeIcon() {

  const week = weekDaysShort[parseInt(moment().format('d'))]
  const day = moment().format('D');

  return (
    <div className="header-datetime">
      <div className="header-datetime-value">
        <span className="header-datetime-week">{week}</span>
        <div className="header-datetime-day">{day}</div>
      </div>
      <div className="header-datetime-border"/>
    </div>
  )

}

function UserInfo() {

  const dispatch = useDispatch()
  const user = useSelector((state: AppState) => state.user.user)

  const ref = useRef(null)
  const [open, setOpen] = useState(false)

  const handleClickOutside = () => {
    setOpen(false)
  }

  useOnClickOutside(ref, handleClickOutside)

  const signOut = () => {

    dispatch(userSignOutAction())

  }

  if (user) {
    return (
      <div className="header-user" ref={ref}>
        <div className="header-user-top" onClick={() => {setOpen(!open)}}>
         <div className="header-user-top-icon">
           <span className="header-user-top-icon-label">D</span>
         </div>
        </div>
        {open && (
          <div className="header-user-submenu">
            <div className="header-user-submenu-inner">
              <div className="header-user-submenu-top">
                <div className="header-user-submenu-icon">
                  <div className="header-user-submenu-icon-label">D</div>
                </div>
                <div className="header-user-submenu-info">
                  <span className="header-user-submenu-info-title">{user.email}</span>
                  <span className="header-user-submenu-info-subtitle">Dovezem.by</span>
                </div>
              </div>
              <div className="header-user-submenu-elements">
                <div className="header-user-submenu-element" onClick={signOut}>
                  <BiExit/>
                  <span className="header-user-submenu-element-label">Выйти</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  return <div/>

}

function Search() {

  const ref = useRef(null)

  const [search, setSearch] = useState<string>('')
  const [elements, setElements] = useState<ClientType[]>([])
  const [clientModalOpen, openClientModal, closeClientModal, clientModalObj] = useModal()

  const token = useSelector((state: AppState) => state.user.token)

  const handleClickOutside = () => {
    setElements([])
    setSearch('')
  }

  useOnClickOutside(ref, handleClickOutside)

  const searchElements = async (input: string) => {

    setSearch(input)
    if (token && input.length >= 2) {
      const elements = await apiClientsSearch(token, search)
      setElements(elements)
    } else {
      setElements([])
    }

  }

  return (
    <div className="header-search" ref={ref}>
      <ClientsModal
        client={clientModalObj ? clientModalObj.client : null}
        onClose={closeClientModal}
        open={clientModalOpen}
      />
      <div className="header-search-top">
        <input
          type="text"
          placeholder="Поиск клиента"
          value={search}
          onChange={event => {searchElements(event.target.value)}}
        />
        <div className="header-search-icon">
          <AiOutlineSearch size={20}/>
        </div>
      </div>
      <div className="header-search-submenu">
        {elements.map(element => {
          return (
            <div className="header-search-submenu-element" onClick={() => {
              openClientModal({
                client: element
              })
              setSearch('')
              setElements([])
            }}>
              <span className="header-search-submenu-element-phone">{element.phone}</span>
              <span className="header-search-submenu-element-name">{element.last_name} {element.first_name} (0/0/0)</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export function Header() {

  return (
    <header className="header">
      <NavLink to="/" className="header-logo">
        <img src={LogoImage} alt="" className="header-logo-image"/>
      </NavLink>
      <Search/>
      <div className="header-right">
        <DateTimeIcon/>
        <BellIcon/>
        <UserInfo/>
      </div>
    </header>
  )
}
