import React, {useEffect, useState} from 'react';
import {Button, useModal} from "../../ui";
import './Stations.css';
import RouteSelect from "../../components/fields/RouteSelect";
import {RouteType, StationType} from "../../types/models";
import {apiStationCreate, apiStationDelete, apiStationsGet, apiStationUpdate} from "../../actions/stationActions";
import {DataSetModal} from "../../ui/components/DataSet/DataSet";
import {fields} from "./fields";
import {modalStructure} from "./structure";
import {generateCreateMethod} from "../../utils/generateCreateMethod";
import StationBlock from "./components/StationBlock";
import {stationTypes} from "./constants";
import {generateUpdateMethod} from "../../utils/generateUpdateMethod";
import {generateDeleteMethod} from "../../utils/generateDeleteMethod";
import {useSelector} from "react-redux";
import {AppState} from "../../reducers/store";

function Stations() {

  const [route, setRoute] = useState<RouteType | null>(null)
  const [stations, setStations] = useState<StationType[]>([])

  const [stationModalOpen, openStationModal, closeStationModal, stationModalObj] = useModal()

  const token = useSelector((state: AppState) => state.user.token)

  useEffect(() => {
    loadStations()
  }, [route])

  const loadStations = async () => {

    if (route && token) {
      const stations = await apiStationsGet(token, route.id)
      setStations([...stations])
    }

  }

  const deleteStation = async (id: number) => {

    const deleteMethod = generateDeleteMethod(token, apiStationDelete)
    await deleteMethod(id)
    loadStations()

  }

  return (
   <>
     <div className="page-top">
       <DataSetModal
         title={'Остановка'}
         open={stationModalOpen}
         onClose={closeStationModal}
         onUpdate={() => {
           loadStations()
           closeStationModal()
         }}
         fields={fields}
         structure={modalStructure}
         type={stationModalObj ? 'update' : 'create'}
         obj={stationModalObj ? stationModalObj : {
           route_id: route ? route.id : null
         }}
         headers={[{
           label: 'Маршрут',
           value:  route ? route.name : ''
         }]}
         method={stationModalObj ? generateUpdateMethod(token, apiStationUpdate) : generateCreateMethod(token, apiStationCreate)}
       />
       <div className="page-top-left">
         <span className="page-title">Остановки</span>
       </div>
       <div className="page-top-right">
          <RouteSelect
            width={'300px'}
            value={route}
            onChange={(route) => {setRoute(route)}}
          />
         {route !== null && <Button onClick={openStationModal} label={'Создать'} type={'primary'} size={'small'} className={'margin-left-20'}/>}
       </div>
     </div>
     <div className="page-content">
      <div className="stations">
        {stations.map(station => (
          <StationBlock
            key={station.id}
            station={station}
            onDeletePress={() => {
              deleteStation(station.id)
            }}
            onEditPress={() => {
              openStationModal({
                ...station,
                type: stationTypes.find(type => type.id === station.type)
              })
            }}
          />
        ))}
      </div>
     </div>
   </>
  );
}

export default Stations;
