import React from 'react'
import './Tabs.css'

function HTabs({tabs, activeTab, onChange}: {
  tabs: string[],
  activeTab: string,
  onChange: (tab: string) => void
}) {

  return (
    <div className="h-tabs">
      {tabs.map(tab => {
        return (
          <div className={`h-tab ${activeTab === tab ? 'active' : ''}`} onClick={() => {
            onChange(tab)
          }}>
            <span className="h-tab-label">{tab}</span>
          </div>
        )
      })}
    </div>
  )


}

export default HTabs;
