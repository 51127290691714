import {HField} from "../../ui/types";
import CitySelect from "../../components/fields/CitySelect";
import {stationTypes} from "./constants";

export const fields: HField[] = [
  {
    component: 'textInput',
    label: 'Название',
    key: 'name',
    required: true,
  },
  {
    component: CitySelect,
    label: 'Населенный пункт',
    key: 'city',
    required: false,
  },
  {
    component: 'select',
    label: 'Тип',
    key: 'type',
    required: true,
    elements: stationTypes,
    renderValue: (value: any) => value.name,
    placeholder: 'Выберите тип',
    defaultValue: stationTypes[0]
  },
  {
    component: 'textInput',
    label: 'Время, мин',
    key: 'time',
    required: true,
    defaultValue: 0
  },
]
