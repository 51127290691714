import {API_URL} from "../constants";
import axios from "axios";

export const apiClientSearch = (token: string, phone: string): any => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/client/search?phone=${phone}`, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiClientsSearch = (token: string, input: string): any => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/clients/search?input=${input}`, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' +   token,
        },
      })
      .then(function (response) {
        resolve(response.data.clients)
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
