import './LeftMenu.css'
import { FaUsers } from "react-icons/fa6";
import {BiMoney, BiStats, BiTrip} from "react-icons/bi";
import {AiOutlineCar, AiOutlineIdcard, AiOutlineSchedule} from "react-icons/ai";
import {LiaCitySolid, LiaMapMarkerAltSolid, LiaMoneyBillAlt} from "react-icons/lia";
import {NavLink} from "react-router-dom";
import {ReactElement} from "react";

const links: {
  label: string,
  icon: ReactElement,
  to: string
}[] = [
  {
    label: 'Рейсы',
    icon: <BiTrip/>,
    to: '/trips'
  },
  {
    label: 'Расписание',
    icon: <AiOutlineSchedule/>,
    to: '/schedules'
  },
  {
    label: 'Транспорт',
    icon: <AiOutlineCar/>,
    to: '/cars'
  },
  {
    label: 'Водители',
    icon: <AiOutlineIdcard/>,
    to: '/drivers'
  },
  {
    label: 'Остановки',
    icon: <LiaMapMarkerAltSolid/>,
    to: '/stations'
  },
  {
    label: 'Населенные пункты',
    icon: <LiaCitySolid/>,
    to: '/cities'
  },
  {
    label: 'Цены',
    icon: <BiMoney/>,
    to: '/prices'
  },
]

export function LeftMenu() {
  return (
    <div className='left-menu'>
      {links.map(link => {
        return (
          <NavLink key={link.label} to={link.to} className="left-menu-element">
            {link.icon}
            <span className='left-menu-element-label'>{link.label}</span>
          </NavLink>
        )
      })}
    </div>
  )
}
