import React from "react";
import {FiEdit2} from "react-icons/fi";
import {BsTrash3} from "react-icons/bs";
import {AiOutlineCar, AiOutlineIdcard} from "react-icons/ai";
import {ScheduleType} from "../../../types/models";

export default function ScheduleElement({schedule, onEditPress, onDeletePress}: {
  schedule: ScheduleType,
  onEditPress: () => void,
  onDeletePress: () => void
}) {

  const onDelete = () => {

    if (window.confirm('Удалить расписание?')) {
      onDeletePress()
    }

  }

  return (
    <div className="schedules-element">
      <div className="schedules-element-icons">
        <span className="schedules-element-icon" onClick={onEditPress}><FiEdit2/></span>
        <span className="schedules-element-icon" onClick={onDelete}><BsTrash3/></span>
      </div>
      <div className="schedules-element-time">{schedule.time}</div>
      <div className="schedules-element-dop">
        {schedule.car && (
          <span className="schedules-element-dop-icon"><AiOutlineCar/></span>
        )}
        {schedule.driver && (
          <span className="schedules-element-dop-icon"><AiOutlineIdcard/></span>
        )}
      </div>
    </div>
  )

}
