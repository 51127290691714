import {HTableConfig} from "../../ui/components/Table";

export const tableConfig: HTableConfig = {
  heads: [
    {
      key: 'route',
      label: 'Маршрут',
      render: value => value.name
    },
    {
      key: 'city_from',
      label: 'От',
      render: value => value.name
    },
    {
      key: 'city_to',
      label: 'До',
      render: value => value.name
    },
    {
      key: 'price',
      label: 'Цена'
    },
  ]

}
