import {HField} from "../../ui/types";

export const fields: HField[] = [
  {
    component: 'textInput',
    label: 'ФИО',
    key: 'fio',
    required: true,
  },
  {
    component: 'textInput',
    label: 'Номер телефона',
    key: 'phone',
    required: true,
  },
  {
    component: 'select',
    label: 'Доступ в приложение',
    key: 'hasMobileAccess',
    required: true,
    elements: ['Нет', 'Да'],
    defaultValue: 'Нет'
  },
  {
    component: 'textInput',
    label: 'Пароль',
    key: 'password',
    required: false,
    hidden: true
  },
  {
    component: 'textInput',
    label: 'Новый пароль',
    key: 'newPassword',
    required: false,
  },
]
