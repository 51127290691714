import React, {useRef, useState} from 'react';
import './Select.scss';
import {ArrowDownIcon} from "../../assets/icons";
import {useOnClickOutside} from "usehooks-ts";

type SelectProps = {
  id?: string
  value: any
  elements?: any[]
  placeholder?: string
  onChange?: (element: any) => void
  width?: string
  renderValue?: (value: any) => string
  nullValue?: string
}

export function Select({
  id,
  elements = [],
  value,
  placeholder = '',
  onChange,
  width,
  renderValue,
  nullValue
}: SelectProps) {

  const ref = useRef(null)
  const [open, setOpen] = useState(false)

  const handleClickOutside = () => {
    setOpen(false)
  }

  useOnClickOutside(ref, handleClickOutside)

  const getValue = (): string => {

    if (value === null) {
      return placeholder
    }

    if (renderValue) {
      return renderValue(value)
    }

    if (typeof value === 'string') {
      return value
    }

    return ''

  }

  return (
    <div className="h-select" style={{width}} id={id} ref={ref}>
      <div className={`h-select-top ${open && 'h-select-top-opened'}`} onClick={() => setOpen(!open)}>
        <span className="h-select-top-label">{getValue()}</span>
        <img src={ArrowDownIcon} className={`h-select-top-arrow ${open && 'h-select-top-arrow-opened'}`}/>
      </div>
      {open && (
        <ul className="h-select-submenu">
          {nullValue && (
            <li onClick={() => {
              onChange && onChange(null)
              setOpen(false)
            }}>{nullValue}</li>
          )}
          {elements.map((element, index) => {
            return (
              <li key={`${id}index`} onClick={() => {
                onChange && onChange(element)
                setOpen(false)
              }}>{renderValue ? renderValue(element) : element}</li>
            )
          })}
        </ul>
      )}
    </div>
  );
}
