import {HField} from "../../ui/types";
import CitySelect from "../../components/fields/CitySelect";
import RouteSelect from "../../components/fields/RouteSelect";

export const fields: HField[] = [
  {
    component: 'textInput',
    label: 'Цена',
    key: 'price',
    required: true,
  },
  {
    component: CitySelect,
    label: 'От города',
    key: 'city_from',
    required: false,
  },
  {
    component: CitySelect,
    label: 'До города',
    key: 'city_to',
    required: false,
  },
  {
    component: RouteSelect,
    key: 'route',
    required: true,
    label: 'Маршрут'
  }
]
