import React from 'react';
import {
  DataSet,
} from "../../ui/components/DataSet/DataSet";
import {fields} from "./fields";
import {tableConfig} from "./table";
import {createModalStructure, updateModalStructure} from "./structure";
import {generateCreateMethod} from "../../utils/generateCreateMethod";
import {generateGetMethod} from "../../utils/generateGetMethod";
import {apiDriverCreate, apiDriversGet, apiDriverUpdate} from "../../actions/driverActions";
import {passwordFieldPlugin} from "./plugins/passwordFieldPlugin";
import {generateUpdateMethod} from "../../utils/generateUpdateMethod";
import {useSelector} from "react-redux";
import {AppState} from "../../reducers/store";

function Drivers() {

  const token = useSelector((state: AppState) => state.user.token)

  return (
   <>
     <DataSet
       title={'Водители'}
       tableConfig={tableConfig}
       methods={{
         get: generateGetMethod(token, apiDriversGet),
         create: generateCreateMethod(token, apiDriverCreate),
         update: generateUpdateMethod(token, apiDriverUpdate)
       }}
       modals={{
         create: {
           title: 'Водитель',
           structure: createModalStructure,
           plugins: [passwordFieldPlugin],
         },
         update: {
           title: 'Водитель',
           structure: updateModalStructure,
           plugins: [passwordFieldPlugin],
           generateFields: element => {
             return {
               ...element,
               hasMobileAccess: element.hasMobileAccess === 1 ? 'Да' : 'Нет'
             }
           }
         }
       }}
       fields={fields}
     />
   </>
  );
}

export default Drivers;
