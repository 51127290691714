import {API_URL} from "../constants";
import axios from "axios";

export const apiCarCreate = (token: string, {
  mark,
  model,
  number,
  color,
  capacity
}: any): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/car/create`, {
        mark,
        model,
        number,
        color,
        capacity
      }, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiCarUpdate = (token: string, id: number, {
  mark,
  model,
  number,
  color,
  capacity
}: any): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/car/update`, {
        id,
        mark,
        model,
        number,
        color,
        capacity
      }, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiCarDelete = (token: string, id: number): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/car/delete`, {
        id,
      }, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiCarsGet = (token: string, {
  offset, limit, search
}: {
  offset: number,
  limit: number,
  search: string
}): any => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/cars?offset=${offset}&limit=${limit}&search=${search}`, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve({
          elements: response.data.cars,
          num: response.data.num
        });
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

