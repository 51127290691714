  import {HField} from "../types";
  import {isEmpty} from "./isEmpty";

export const generateInitialState = (fields: HField[]) => {

  const state: Record<string, any> = {}

  fields.forEach(field => {
    state[field.key as string] = !isEmpty(field.defaultValue) ? field.defaultValue : null
  })

  return state

}
