import {HTableConfig} from "../../ui/components/Table";

export const tableConfig: HTableConfig = {
  heads: [
    {
      key: 'fio',
      label: 'ФИО'
    },
    {
      key: 'phone',
      label: 'Номер телефона'
    },
    {
      key: 'hasMobileAccess',
      label: 'Приложение',
      render: (value) => {
        return parseInt(value) === 1 ? 'Есть доступ' : 'Нет доступа'
      }
    },
  ]

}
