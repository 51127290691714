import React, {useEffect, useState} from 'react';
import {Select} from "../../ui";
import {CarType} from "../../types/models";
import {apiCarsGet} from "../../actions/carActions";
import {useSelector} from "react-redux";
import {AppState} from "../../reducers/store";

export default function CarSelect({value, onChange}: {
  value: any,
  onChange: (value: any) => void,
}) {

  const token = useSelector((state: AppState) => state.user.token)
  const [cars, setCars] = useState([])

  useEffect(() => {
    loadElements()
  }, [])

  const loadElements = async () => {

    if (token) {
      const response = await apiCarsGet(token, {offset: 0, limit: 50, search: ''})
      setCars(response.elements)
    }

  }

  return (
    <Select
      value={value}
      placeholder={'Транспорт'}
      elements={cars}
      onChange={onChange}
      renderValue={(car: CarType) => `${car.mark} ${car.model} ${car.number}`}
      nullValue={'Не назначена'}
    />
  )

}
