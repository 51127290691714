import {HField} from "../../ui/types";
import DriverSelect from "../../components/fields/DriverSelect";
import CarSelect from "../../components/fields/CarSelect";

export const fields: HField[] = [
  {
    component: 'textInput',
    label: 'Время',
    key: 'time',
    required: true,
    mask: '99:99'
  },
  {
    component: DriverSelect,
    label: 'Водитель',
    key: 'driver',
    required: false,
  },
  {
    component: CarSelect,
    label: 'Трансопрт',
    key: 'car',
    required: false,
  },
]
