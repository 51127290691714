import {HFormStructure} from "../../ui/types";

export const createModalStructure: HFormStructure = [
  ['fio', 'phone'],
  ['hasMobileAccess', 'password']
]

export const updateModalStructure: HFormStructure = [
  ['fio', 'phone'],
  ['hasMobileAccess', 'newPassword']
]
