import {API_URL} from "../constants";
import axios from "axios";

export const apiCityCreate = (token: string, {
  name
}: any): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/city/create`, {
        name
      }, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiCityUpdate = (token: string, id: number, {
  name
}: any): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/city/update`, {
        id,
        name
      }, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiCityDelete = (token: string, id: number): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/city/delete`, {
        id,
      }, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiCitiesGet = (token: string, {
  offset, limit, search
}: {
  offset: number,
  limit: number,
  search: string
}): any => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/cities?offset=${offset}&limit=${limit}&search=${search}`, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve({
          elements: response.data.cities,
          num: response.data.num
        });
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

