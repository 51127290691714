import {API_URL} from "../constants";
import axios from "axios";

export const apiScheduleCreate = (token: string, {
  time,
  week,
  car,
  driver,
  route_id
}: any): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/schedule/create`, {
        time,
        week,
        car_id: car ? car.id : null,
        driver_id: driver ? driver.id : null,
        route_id,
      }, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiScheduleUpdate = (token: string, id: number, {
  time,
  car,
  driver,
}: any): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/schedule/update`, {
        id,
        time,
        car_id: car ? car.id : null,
        driver_id: driver ? driver.id : null,
      }, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiScheduleDelete = (token: string, id: number): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/schedule/delete`, {
        id,
      }, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiSchedulesGet = (token: string, route_id: number): any => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/schedules?route_id=${route_id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data.schedules)
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

