import React from "react";
import {FiPlus} from "react-icons/fi";

export default function ScheduleWeek({week, onCreatePress}: {onCreatePress: () => void, week: {
    label: string
    key: number
  }}) {

  return (
    <div className="schedules-top-element">
      <span className="schedules-top-element-label">{week.label}</span>
      <div className="schedules-top-element-icon" onClick={onCreatePress}>
        <FiPlus/>
      </div>
    </div>
  )

}
