import axios from "axios";
import {API_URL} from "../constants";

export const apiRoutesGet = (token: string): any => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/routes`, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data.routes);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
