import {HField} from "../../ui/types";

export const fields: HField[] = [
  {
    component: 'textInput',
    label: 'Название',
    key: 'name',
    required: true,
  },
]
