import {DataSetCreateFunction} from "../ui/components/DataSet/DataSet";

type GenerateCreateMethod = (token: string | null, method: Function) => DataSetCreateFunction

export const generateCreateMethod: GenerateCreateMethod = (token, method) => {

  return obj => {
    return new Promise(async resolve => {
      await method(token, obj)
      resolve({})
    })
  }

}
