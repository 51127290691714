import {monthToLabel, weekDaysShort} from "../constants";
import moment from "moment";

export const getDateTimeString = (date: Date | null, short = false): string => {

  if (date === null) {
    return ''
  }

  const week = weekDaysShort[parseInt(moment(date).format('d'))]

  return `${moment(date).format('D')} ${monthToLabel[moment(date).format('M')]} (${week})`

}
